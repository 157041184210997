/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react'

import {
  Container, Title, SubTitle, Question, Answer, Link,
} from './styles'

import { musicgear, prints } from './data'

const Faq = () => (
  <Container>
    <Title>FAQ</Title>
    <SubTitle>Music Gear</SubTitle>
    {
      musicgear.map(({ q, a }, index) => (

        <Fragment key={index}>
          <Question>{q}</Question>
          <>
            {
              a.map((line, i) => (
                <Answer key={i}>
                  {line}
                </Answer>
              ))
            }
          </>
        </Fragment>
      ))
    }
    <SubTitle>Prints</SubTitle>
    {
      prints.map(({ q, a, link }, index) => (
        <Fragment key={index}>
          {!!q && (<Question>{q}</Question>)}
          <>
            {
              a.map((line, i) => (
                <Answer key={i}>
                  {line}
                </Answer>
              ))
            }
            {
              !!link && (
                link.map((l, i) => (
                  <Link key={i} href={l.href} target="_blank">
                    {l.label}
                  </Link>
                ))
              )
            }
          </>
        </Fragment>
      ))
    }
  </Container>
)

export default Faq
