import React from 'react'

import Layout from '../Layout'
import SEO from '../SEO'

import FaqContent from '../components/FAQ'

const Faq = () => (
  <Layout withPadding={false} restricted={false}>
    <SEO title="FAQ" keywords={['question frequente', 'FAQ']} />
    <FaqContent />
  </Layout>
)

export default Faq
