import styled from 'styled-components'
import { ClassicLink } from '../StyledLink'

export const Container = styled.div`
  max-width: 500px;
  margin: 20px auto 0;
`

export const Title = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  margin-bottom: 30px;
`

export const SubTitle = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  margin-bottom: 10px;
  margin-top: 30px;
`

export const Question = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  text-align: justify;
  margin-bottom: 3px;
  margin-top: 35px;
`

export const Answer = styled.div`
  color: ${({ theme: { color } }) => color.text};
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  text-align: justify;
  line-height: 1.5;
`

export const Link = styled(ClassicLink)`
  color: ${({ theme: { color } }) => color.secondary.main};
  padding: 3px;
  display: block;
  text-decoration: underline;
`
