/* eslint-disable max-len */
export const musicgear = [
  {
    q: 'Avec quels matériaux sont fabriqués vos produits ?',
    a: ['Tous nos produits cabs et pedalboards sont fabriqués en contreplaqué de bouleau finlandais.'],
  },
  {
    q: 'Puis-je obtenir un haut-parleur ou une combinaison de haut-parleurs différents de ceux énumérés ?',
    a: ['Tout à fait, nous avons une sélection de HP’s avec lesquels nous aimons travailler mais nous sommes toujours ouverts aux projets de nos clients.'],
  },
  {
    q: 'Est-il possible de commander un cab vide (sans haut-parleurs) ?',
    a: ['Oui, envoyez-nous un message pour connaître les prix.'],
  },
  {
    q: 'Si je passe commande, en combien de temps pourrais-je recevoir mon cab/pedalboard ?',
    a: ['Tous nos produits sont fabriqués sur commande et à la main, il faudra compter entre 1 et 2 mois pour un cab et entre 2 semaines et 1 mois pour un pedalboard. Ces délais sont donnés à titre indicatif, ils peuvent être plus courts ou plus longs selon le nombre de commandes en cours.'],
  },
  {
    q: 'J’ai un ampli combo et j’aimerais le convertir en un format tête + cab, est-ce possible ?',
    a: ['Oui, nous faisons régulièrement ce type de conversion. Soit en construisant une nouvelle enclosure pour la partie amplification et en adaptant le corps du combo en cab, soit en faisant un nouveau cab.'],
  },
  {
    q: 'Puis-je venir chercher ma commande au lieu de la faire expédier ?',
    a: ['Oui, il est possible de venir chercher votre commande directement à l’atelier.'],
  },
  {
    q: 'Quelle est votre politique de garantie ?',
    a: ['Nos produits sont garantis à vie contre les éventuels défauts de fabrication, hors haut-parleurs, connectiques et câblage. Pour ces éléments nous nous référons aux garanties proposées par les constructeurs.'],
  },
  {
    q: 'Proposez-vous des réductions/endorsements aux artistes ?',
    a: ['Oui, nous pouvons faire des réductions pour les artistes qui partent en tournées et pour les studios d’enregistrement. Contactez-nous avec vos infos.'],
  },
  {
    q: 'Offrez-vous des plans de paiement ?',
    a: ['Toutes les commandes doivent normalement être payées d’avance, mais il est également possible de payer la moitié du prix au moment de la commande et le reste à la réception.'],
  },
  {
    q: 'Où puis-je essayer/voir les cabs et pedalboards Old Tree ?',
    a: ['Il est possible de venir directement dans notre atelier (nous contacter pour convenir d’un rendez-vous). Nous faisons parfois du dépôt-vente dans quelques boutiques, contactez-nous pour savoir lesquelles : les stocks d’expo sont parfois déplacés.'],
  },
]

export const prints = [
  {
    q: "La sérigraphie, c'est quoi ?",
    a: ["La sérigraphie est une technique d'impression qui peut être artisanale et manuelle ou bien automatisée et à grande échelle. Elle est la plus utilisée pour l'impression textile, même si l'impression numérique gagne du terrain. Son principe est simple, c'est celui du pochoir sur un écran en nylon et au travers duquel on fait passer de l'encre à l'aide d'une racle. C'est notamment l'une des techniques qu'utilisait Andy Warhol pour nombre de ses oeuvres."],
  },
  {
    q: "Quel type d'encre utilisez-vous ?",
    a: ["Nous utilisons des encres à eau, non nocives et très peu polluantes, qui ne nécessitent pas l'utilisation de solvants au nettoyage. Nous avons choisi les encres de la marque Belge SEBEK Inks, qui sont vegan et certifiées Oeko Tex 100 Classe 1 et REACH."],
  },
  {
    q: 'Sur quel textile imprimez-vous ?',
    a: ['Nos impressions de t-shirts se font sur Gildan Heavy Cotton G5000 pour les t-shirts droits et sur Gildan Premium Cotton Ladies GI4100L pour les t-shirts cintrés. Les impressions sur t-shirts fair trade en coton bio se font sur B&C TM042 et TM043. Voir les liens ci-dessous pour les descriptifs détaillés et les couleurs disponibles (attention, les couleurs disponibles ne sont pas forcément les mêmes selon les modèles) :'],
    link: [
      {
        label: 'T-shirts droits',
        href: 'https://www.wordans.fr/gildan-gi5000-t-shirt-manches-courtes-en-coton-1516',
      },
      {
        label: 'T-shirts cintrés',
        href: 'https://www.wordans.fr/gildan-4100l-t-shirt-femme-premium-100-coton-32704/',
      },
      {
        label: 'T-shirts droits en coton bio équitable',
        href: 'https://www.bc-collection.eu/fr/t-shirts/bc-inspire-t-men-tm042',
      },
      {
        label: 'T-shirts cintrés en coton bio équitable',
        href: 'https://www.bc-collection.eu/fr/t-shirts/bc-inspire-t-women-tw043',
      },
    ],
  },
  {
    a: ['Nos impressions de tote bags se font sur Westford Mill WM101. Voir le lien ci-dessous pour le descriptif détaillé et les couleurs disponibles :'],
    link: [
      {
        label: 'Tote bags',
        href: 'http://www.westfordmill.com/product.php?sku=W101&c=cotton-shoppers&cid=95',
      },
    ],
  },
  {
    q: 'Quel est le minimum de t-shirts à imprimer que je puisse commander ?',
    a: ['Nous acceptons les commandes à partir de 20 exemplaires du même visuel, imprimé de la même couleur et même taille. Le travail de préparation est souvent plus longue que le temps d\'impression, c\'est pourquoi nous ne faisons pas d\'impressions à l\'unité.'],
  },
  {
    q: 'Quel est le temps moyen de livraison ?',
    a: ['Notre délai moyen de livraison est de 3 à 4 semaines à partir du moment où nous avons reçu le paiement intégral pour la commande. Une expédition sous 2 semaines est aussi possible selon la quantité travail en cours et nos disponibilités, moyennant un surcoût (voir devis en ligne).'],
  },
  {
    q: 'Est-ce que je peux choisir les tailles et couleurs de mes t-shirts/sacs ?',
    a: ['Oui, le panachage des tailles et couleurs est gratuit et illimité. Nous vous demanderons tout de même de limiter le choix des couleurs de t-shirts à 2 pour toute commande de moins de 50 unités. Par contre, les couleurs d\'impression du visuel sont fixes.'],
  },
  {
    q: 'Est-ce que je peux placer mon visuel où je veux, sur le t-shirt ?',
    a: ['Non, nous n\'imprimons pas sur les coutures ni sur les manches. Après, que ce soit devant, derrière, en haut ou en bas, en diagonale, etc, ne change rien pour nous et est tout à fait possible. Pensez à nous fournir un visuel d\'exemple de t-shirt avec votre logo placé où vous le souhaitez pour éviter toute confusion.'],
  },
  {
    q: 'Pourquoi dois-je payer le tarif impression deux couleurs, si je veux un tee shirt imprimé en recto-verso de la même couleur ?',
    a: ['Même si la couleur est la même, le travail est doublé. Il faut préparer un deuxième écran, refaire les calages pour l\'impression et imprimer une deuxième fois le t-shirt, tout simplement.'],
  },
  {
    q: 'Comment faire si je veux imprimer sur un support que vous ne proposez pas ? Ou en plus de couleurs ?',
    a: ['N\'hésitez pas à nous contacter à l\'adresse e-mail prints(A)oldtree.fr, nous répondrons à toutes vos questions !'],
  },
]
